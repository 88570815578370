import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO, Category, CategoryNav } from 'components';
import SearchBar from '../components/Faqs/SearchBar';
import './blogCategory.scss';

export const BlogCategory = props => {
  const { location, data, pageContext } = props;
  // const categoryPosts = data.allPrismicBlogPost.nodes;
  const { posts } = data;
  const { nodes } = posts;
  const { category } = pageContext;

  // destructuring query

  const paginationAmount = 4;

  // search bar

  const [visiblePosts, setVisiblePosts] = useState(nodes ? [...nodes].splice(0, paginationAmount) : []); // set visible posts
  const [numberOfPosts, setNumberOfPosts] = useState(paginationAmount); // set search query
  const [searchQuery, setSearchQuery] = useState(''); // set search query
  const [searchActive, setSearchActive] = useState(false); // set search activate

  const setSearchHandler = event => {
    const query = event.target.value.toLowerCase() || '';
    setSearchQuery(query);
    queryQuestions(query);
  };

  const queryQuestions = query => {
    const filteredPosts = [...nodes].filter(blogItem => {
      const queryMatches = query ? blogItem.data.post_title.text.toLowerCase().includes(query) : true;
      return queryMatches;
    });
    setVisiblePosts(filteredPosts);
  };

  const searchFocusHandler = (event, clicked) => {
    event.preventDefault();
    setSearchActive(clicked);
  };

  const handleLoadMore = () => {
    const newNumberOfPosts = numberOfPosts + paginationAmount;
    setNumberOfPosts(newNumberOfPosts);
    setVisiblePosts([...nodes].splice(0, newNumberOfPosts));
  };

  console.log(category);
  return (
    <Layout location={location}>
      <SEO title={category} />
      <div className="category">
        <div className="category-wrapper">
          <h1 className="category-title">{category}</h1>
        </div>
        <CategoryNav />
        <SearchBar
          searchActive={searchActive}
          searchQuery={searchQuery}
          setSearchHandler={setSearchHandler}
          searchFocusHandler={searchFocusHandler}
        />
        <Category sortedPosts={posts} posts={visiblePosts} onLoadMore={handleLoadMore} numberOfPosts={numberOfPosts} />
      </div>
    </Layout>
  );
};

export default BlogCategory;

export const pageQuery = graphql`
  query($category: String!) {
    site {
      siteMetadata {
        siteName
      }
    }
    posts: allPrismicBlogPost(filter: { data: { category: { eq: $category } } }) {
      nodes {
        uid
        id
        data {
          featured_image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          post_description {
            text
          }
          post_title {
            text
          }
        }
      }
    }
  }
`;
